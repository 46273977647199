import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "../technical/BlockWrapper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-solid-svg-icons"

const SEPERATOR = "..."

function paginate(c, m) {
  var current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push(SEPERATOR)
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

const Pagination = ({
  currentPage,
  totalPages,
  isFirstPage,
  isLastPage,
  prevPage,
  nextPage,
  pagePath,
}) => {
  const paginationPages = paginate(currentPage, totalPages)

  console.log(isFirstPage)
  console.log(isLastPage)

  return (
    <BlockWrapper block={{ component: "pagination" }} blockPadding="pb-block">
      <nav>
        <div className="inline-block text-primary">
          <Link
            className={`px-4 py-2 border  ${
              !isFirstPage
                ? " border-primary hover:bg-primary-hover hover:text-white"
                : "border-gray-400 text-gray-400"
            }`}
            link={!isFirstPage ? prevPage : ""}
            rel="prev"
          >
            <FontAwesomeIcon icon={faCaretLeft} size="1x" />
          </Link>
          {paginationPages.map((value, index) => {
            if (value === SEPERATOR) {
              return (
                <span
                  className="px-3 py-2 text-gray-400 border border-gray-400"
                  key={index}
                >
                  {SEPERATOR}
                </span>
              )
            } else {
              return (
                <Link
                  className="px-4 py-2 border border-primary hover:bg-primary-hover hover:text-white"
                  activeClass="bg-primary text-white"
                  key={index}
                  link={`${pagePath}/${value === 1 ? "" : value}`}
                  rel={
                    value - 1 === currentPage && value <= totalPages
                      ? "next"
                      : value + 1 === currentPage && value > 0
                      ? "prev"
                      : ""
                  }
                >
                  {value}
                </Link>
              )
            }
          })}
          <Link
            className={`px-4 py-2 border  ${
              !isLastPage
                ? " border-primary hover:bg-primary-hover hover:text-white"
                : "border-gray-400 text-gray-400"
            }`}
            link={!isLastPage ? nextPage : ""}
            rel="next"
          >
            <FontAwesomeIcon icon={faCaretRight} size="1x" />
          </Link>
        </div>
      </nav>
    </BlockWrapper>
  )
}

export default Pagination
